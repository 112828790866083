var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isNew)?_c('v-expansion-panel',{on:{"click":_vm.handleExpand}},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t('booking.limitFieldsTime.title', _vm.locale))+" [byWorkspace]")]),_c('v-expansion-panel-content',{staticClass:"form-fields-list-main"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"padding":"20px 20px 10px 20px"},attrs:{"xs12":""}},[_c('small',{staticStyle:{"color":"red","font-size":"12px","line-height":"1px"}},[_vm._v("NOTA: afecta a "),_c('b',[_vm._v("todas")]),_vm._v(" las actividades del gestor, si reservas en la act1 no puedes reservar en la act2, act3, etc.")]),(_vm.formFields)?_c('v-autocomplete',{staticStyle:{"margin-top":"20px"},attrs:{"items":_vm.formFields,"outlined":"","chips":"","itemText":"name","itemValue":"id","small-chips":"","label":"campos","multiple":"","hide-details":""},on:{"change":_vm.handleChange},model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}}):_vm._e()],1),(_vm.selectedFields && _vm.selectedFields.length > 1)?_c('v-flex',{staticStyle:{"padding":"10px 20px 10px 20px"},attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":_vm.modes,"itemText":"name","itemValue":"id","label":"Modo","hide-details":"","outlined":""},on:{"change":_vm.handleChange},model:{value:(_vm.selectedMode),callback:function ($$v) {_vm.selectedMode=$$v},expression:"selectedMode"}})],1):_vm._e(),_c('v-flex',{staticStyle:{"padding":"10px 10px 20px 20px"},attrs:{"xs6":""}},[_c('custom-text-field-number',{attrs:{"label":"Tiempo"},on:{"input":_vm.handleChange},model:{value:(_vm.timeLimit),callback:function ($$v) {_vm.timeLimit=$$v},expression:"timeLimit"}})],1),_c('v-flex',{staticStyle:{"padding":"10px 20px 20px 10px"},attrs:{"xs6":""}},[_c('v-select',{attrs:{"items":[{
              id: 'days',
              name:  _vm.$t('booking.activities.days', _vm.locale),
            },
            {
              id: 'minutes',
              name:  _vm.$t('booking.activities.minutes', _vm.locale),
            } ],"itemText":"name","itemValue":"id","outlined":"","hide-details":""},on:{"change":_vm.handleChange},model:{value:(_vm.selectedTimeType),callback:function ($$v) {_vm.selectedTimeType=$$v},expression:"selectedTimeType"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }