<template>
  <v-text-field
    outlined
    v-model="number"
    :label="label"
    hide-details
    type="number"
    :max="max"
    :dense="dense"
    @keypress="isNumber($event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    number: null,
  }),
  watch: {
    value () {
      this.prepareValue()
    },
    number (v) {
      if (v) this.$emit('input', v | 0)
    },
  },
  mounted () {
    this.prepareValue()
  },
  methods: {
    prepareValue () {
      this.number = this.value | 0
    },
    isNumber (evt) {
      evt = (evt) ? evt : window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) evt.preventDefault()
      else return true
    }
  },
}
</script>

